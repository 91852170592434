import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { Switch, SwitchField } from "../catalyst/switch";
import { Description, Label } from "../catalyst/fieldset";
import { useNavigate, useSearchParams } from "react-router-dom";

function BuyButtons() {
  const { user } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const [yearlyPlan, setYearlyPlan] = React.useState(
    searchParams.get("plan") && searchParams.get("plan") === "monthly"
      ? false
      : true
  );

  const handlePlanChange = (val) => {
    setSearchParams({ plan: val === true ? "yearly" : "monthly" });
    setYearlyPlan(val);
  };

  if (!user) return null;
  return (
    <div className="">
      <div className="p-0.5 border bg-[#303032] rounded-lg  border-[#ffffff33] w-fit m-auto">
        <div className=" text-[#bcbcbc]  w-72 relative flex text-center">
          <div
            className={`bg-[#9f0ff2] transition left-0  ${
              !yearlyPlan ? "translate-x-0" : "translate-x-full"
            } w-1/2 h-full rounded-lg absolute`}
          ></div>
          <button
            onClick={handlePlanChange.bind(this, false)}
            className={`transition left-0 w-1/2 h-full ${
              !yearlyPlan ? "text-white" : ""
            } z-10 cursor-pointer font-semibold text-sm py-2`}
          >
            Monthly
          </button>
          <button
            onClick={handlePlanChange.bind(this, true)}
            className={`transition left-0 w-1/2 h-full ${
              yearlyPlan ? "text-white" : ""
            } z-10 cursor-pointer font-semibold text-sm py-2`}
          >
            Yearly
          </button>
        </div>
      </div>

      <div class="isolate mx-auto mt-10 grid max-w-[360px] grid-cols-1 gap-8">
        {yearlyPlan ? (
          <div class="bg-white/5 ring-2 ring-[#9f0ff2] rounded-3xl px-4 py-8 xl:py-10 xl:px-6  animate-fade-up">
            <div class="flex items-center justify-between gap-x-4">
              <h1
                id="tier-startup"
                class="text-lg font-semibold leading-8 text-white"
              >
                Payeee Subscription
              </h1>
              <p class="rounded-full bg-[#9f0ff2] px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                Most popular
              </p>
            </div>
            <div className="mt-8">
              <h2 class="text-lg font-semibold text-white mb-2">Yearly Plan</h2>
              <p class="text-sm leading-6 text-gray-300">
                Get access to all the premium features of Payeee for 10% Off!
              </p>
            </div>
            <p class="mt-10 flex items-baseline gap-x-1">
              <span class="text-2xl mr-1 leading-6 text-gray-500 line-through">
                £179.88
              </span>
              <span class="text-4xl font-bold tracking-tight text-white">
                £161.89
              </span>
              <span class="text-sm font-semibold leading-6 text-gray-300">
                / year
              </span>
            </p>

            <div className="mt-6">
              <stripe-buy-button
                buy-button-id={process.env.REACT_APP_STRIPE_YEARLY_PAYMENT_LINK}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                customer-email={user.email}
              ></stripe-buy-button>
            </div>

            <ul className="mt-6 space-y-3 text-left text-sm leading-6 text-gray-300">
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Access To Thousands of deals
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Gym Membership discounts
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                24/7 Doctors Helpline
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Thousands of Cashback Deals
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Discounts to your favorite restaurants
              </li>
            </ul>
          </div>
        ) : (
          <div class="bg-white/5 ring-2 ring-[#9f0ff2] rounded-3xl px-4 py-8 xl:py-10 xl:px-6  animate-fade-up-2">
            <div class="flex items-center justify-between gap-x-4">
              <h1
                id="tier-startup"
                class="text-lg font-semibold leading-8 text-white"
              >
                Payeee Subscription
              </h1>
            </div>
            <div className="mt-8">
              <h2 class="text-lg font-semibold text-white mb-2">
                Monthly Plan
              </h2>
              <p class="text-sm leading-6 text-gray-300">
                Get access to all the premium features of Payeee!
              </p>
            </div>
            <p class="mt-10 flex items-baseline gap-x-1 justify-center">
              <span class="text-4xl font-bold tracking-tight text-white">
                £14.99
              </span>
              <span class="text-sm font-semibold leading-6 text-gray-300">
                / month
              </span>
            </p>
            <div className="mt-6">
              <stripe-buy-button
                buy-button-id={process.env.REACT_APP_STRIPE_MONTHLY_PAYMENT_LINK}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                customer-email={user.email}
              ></stripe-buy-button>
            </div>
            <ul className="mt-6 space-y-3 text-left text-sm leading-6 text-gray-300 xl:mt-10">
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Access To Thousands of deals
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Gym Membership discounts
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                24/7 Doctors Helpline
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Thousands of Cashback Deals
              </li>
              <li class="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="h-6 w-5 flex-none text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Discounts to your favorite restaurants
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default BuyButtons;
