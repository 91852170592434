import { useState } from "react";
import { Button } from "../components/catalyst/button";
import { Checkbox, CheckboxField } from "../components/catalyst/checkbox";
import { Divider } from "../components/catalyst/divider";
import { Label } from "../components/catalyst/fieldset";
import { Heading, Subheading } from "../components/catalyst/heading";
import { Input } from "../components/catalyst/input";
import { Select } from "../components/catalyst/select";
import { Text } from "../components/catalyst/text";
import { Textarea } from "../components/catalyst/textarea";
import { PageLayout } from "../components/page-layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export const metadata = {
  title: "Support",
};

export default function Support() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    supportType: "feature_request",
    subject: "",
    description: "",
  });

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/support`,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast.success("Email sent successfully!");
      navigate("/");
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <PageLayout>
      <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-4xl">
        <Heading>Support</Heading>
        <Divider className="my-10 mt-6" />

        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>Support Type</Subheading>
            <Text>Select the type of support you need:</Text>
          </div>
          <div>
            <Select
              aria-label="Support Type"
              name="supportType"
              value={formData.supportType}
              onChange={handleChange}
            >
              <option value="feature_request">Feature Request</option>
              <option value="bug_report">Bug Report</option>
              <option value="customer_service">Customer Service</option>
              <option value="other">Other</option>
            </Select>
          </div>
        </section>
        <Divider className="my-10" soft />

        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>Subject</Subheading>
          </div>
          <div className="space-y-4">
            <Input
              type="text"
              aria-label="Support subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
        </section>

        <Divider className="my-10" soft />

        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>Description</Subheading>
            <Text>Please describe what you'd like to get in touch about.</Text>
          </div>
          <div>
            <Textarea
              aria-label="Organization Bio"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
        </section>

        <Divider className="my-10" soft />

        <div className="flex justify-end gap-4">
          <Button disabled={isLoading} className="cursor-pointer" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </PageLayout>
  );
}
